import styles from "../../styles/compliance.module.scss"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import React, { useRef } from "react"
import Layout from "../../components/layout/layout"
import ArrowSvg from "../../components/svg/arrow"
import ExternalLinkSvg from "../../components/svg/external-link"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../../contexts/app.context"
import { Helmet } from "react-helmet"

const CompliancePage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "company__compliance" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  const dssRef = useRef()
  const gdprRef = useRef()
  const ccpaRef = useRef()

  const complianceData = [
    {
      img_url: "/img/company/compliance/pci-dss-compliant.jpg",
      img_lg_url: "/img/company/compliance/pci-dss-compliant-large.png",
      img_alt: "PCI DSS Compliant badge",
      content:
        "<p>Volterra has achieved Payment Card Industry (PCI) Data Security Standard (DSS) Level 1 compliance, the highest and most stringent level of compliance.</p><p>Volterra's Voltmesh service that includes Reverse Proxy, Load-balancer, Web Application Firewall (WAF), and Distributed Denial of Service (DDOS)  on Volterra’s Application Delivery Network (ADN) is PCI DSS Level 1 compliant.</p><p>Volterra is audited annually by a third-party Qualified Security Assessor QSA. Volterra's Attestation of Compliance is available upon request.</p>",
      readmore_link: "#",
      faq_link: "/docs/faqs/generic-faq#compliance",
      ref: dssRef,
      full_desc: [
        {
          title: "What is PCI-DSS compliance?",
          description:
            "The PCI DSS (Payment Card Industry Data Security Standard) is an information security standard designed to increase controls around cardholder data to reduce payment card fraud.",
        },
        {
          title: "Volterra’s PCI-DSS Certification",
          description:
            "Volterra has achieved Level 1 compliance, the highest and most stringent level, allowing us to process more than 6 million transactions annually. As part of PCI DSS certification process, Volterra’s entire global infrastructure has been audited including, VoltConsole, VoltMesh, VoltStack, Volterra Control Plane, all our data centers, as well as our security policies and software development processes. However, the certification focused on VoltMesh service. Volterra does not process nor store cardholder data in any manner since VoltMesh acts as a reverse proxy, load-balancer and app firewall between our customers’ origin servers (merchant or payment service provider) and their end consumers. Volterra treats all communication from the end consumer, which could potentially include PAN (primary account number), security code, and expiration date to the origin server as customer’s sensitive data. The Level 1 certification validates that any action performed on customers’ data by Volterra global infrastructure complies with PCI DSS requirements.",
        },
        {
          title: "Benefit to our Customers",
          description:
            "<p>For e-commerce merchants, payment service providers, and more generally any customer that stores, transmits, or accepts cardholder data, Volterra’s Level 1 certification will greatly facilitate our customers’ own PCI DSS compliance.</p><p>Furthermore by using Volterra’s VoltMesh services that includes a web application firewall (WAF), it will help our customers meet their own PCI requirement 6.6.</p><p>Lastly by complying to the arduous requirements of PCI DSS, we are providing to all our customers an independent and industry-accepted security review of our processes, policies, infrastructure, and software development methodology.</p>",
        },
      ],
    },
    {
      img_url: "/img/company/compliance/gdpr.jpg",
      img_lg_url: "/img/company/compliance/gdpr-large.png",
      img_alt: "GDPR logo",
      content:
        "<p>Volterra's commitment to GDPR compliance</p><p>Our mission is to deliver “Universal Cloud Access” to all users, and we believe the protection of our customers' and their end users' data is fundamental to this mission.</p><p>We minimize our collection of personal data and only use personal data for the purpose for which it was collected. We have committed that we would keep personal information private, so we have never sold or rented our users’ personal information to anyone.</p>",
      readmore_link: "#",
      ref: gdprRef,
      full_desc: [
        {
          title: "What is GDPR compliance?",
          description:
            "European Union (EU) General Data Protection Regulation (GDPR) defines privacy protections and obligations for companies that handle personal data originating in the EU. Any company that processes personal data originating in the EU (whether or not the data subject is a citizen or resident of the EU) or the data of an EU resident—whether the company has operations in the EU or not—is covered by the GDPR.",
        },
        {
          title: "Volterra's commitment to GDPR compliance",
          description:
            "<p>At Volterra, our mission is to deliver “Universal Cloud access” to all users, and we believe the protection of our customers' and their end users' data is fundamental to this mission. We have adhered to stringent standards w.r.t end users’ data even before Europe’s watershed General Data Protection Regulation (GDPR) went into effect in 2018, We minimize our collection of personal data and only use personal data for the purpose for which it was collected. We have committed that we would keep personal information private, so we have never sold or rented our users’ personal information to anyone.</p><h5>We have always followed the guidelines outlined by GDPR</h5><ul><li>Only collect the personal data needed to provide the service offered.</li><li>Don’t sell personal information. </li><li>Give people the ability to access, correct, or delete their personal information.</li><li>Consistent with our role as a data processor, give our customers control over the information captured by our products such as web application firewall (WAF).</li></ul><p>As data protection is an ever-evolving environment, we continue to monitor ongoing developments globally and will update this page as appropriate.If you have any further questions about about how we process data on behalf of our customers in a GDPR compliant fashion please reach out to us at <a href='mailto:support@volterra.io'>support@volterra.io</a>.</p>",
        },
      ],
    },
    {
      img_url: "/img/company/compliance/ca-consumer-privacy-act.jpg",
      img_lg_url: "/img/company/compliance/ca-consumer-privacy-act-large.png",
      img_alt: "California Consumer Privacy Act logo",
      content:
        "<p>Volterra's commitment to CCPA compliance</p><p>California's data privacy act is a governmental framework designed to help safeguard consumers' sensitive personal information.</p><p>Volterra has been adhering to strict standards for our users’ data, as outlined in the GDPR section, even before CCPA went into effect. In addition, Volterra’s patent pending Blindfold technology empowers customers to use their secrets (credentials, certificates) on our platform without giving us access to the secret.</p>",
      readmore_link: "#",
      ref: ccpaRef,
      full_desc: [
        {
          title: "California Consumer Privacy Act (CCPA)",
          description:
            "Similar to Europe's General Data Protection Regulation (GDPR), though with several key differences, California's data privacy act is a governmental framework designed to help safeguard consumers' sensitive personal information. As the digital landscape has evolved over the past decade, the tech sector's notion of consumer rights have expanded - particularly when it comes to sensitive data. With a number of highly-public sensitive data breaches in recent years, personal information - from Social Security Numbers to payment card data - needs to be safeguarded more vigorously than ever before. California's data privacy act, <a href='https://oag.ca.gov/privacy/ccpa' target='_blank'>known as CCPA</a>, is an effort to do just that. It's a governmental framework designed to help make sure organizations are properly protecting their customers' sensitive personal data.",
        },
        {
          title: "Volterra's commitment to CCPA compliance",
          description:
            "<p>Volterra has been adhering to strict standards for our users’ data even before CCPA went into effect. We minimize our collection of personal data and only use personal data for the purpose for which it was collected. We have committed that we would keep personal information private, so we have never sold or rented our users’ personal information to anyone. We give people the ability to access, correct, or delete their personal information; and consistent with our role as a data processor, give our customers control over the information captured by our products.</p><p>In addition, Volterra’s patent pending Blindfold technology enables customers to use their secrets (credentials, passwords, certificates) on our platform without giving us access to the secret.  We put on our Blindfolds when handling your secrets, so that your secrets remain a secret.</p><p>If you have any further questions about about how we process data on behalf of our customers in a CCPA compliant fashion please reach out to us at <a href='mailto:support@volterra.io'>support@volterra.io</a>.</p>",
        },
      ],
    },
  ]

  const smoothScroll = (elementId, e) => {
    e.preventDefault()
    window.scrollTo({
      top: elementId.current.offsetTop - 160,
      behavior: "smooth",
    })
  }

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "http://schema.org",
                "@type": "Corporation",
                "additionalType":"http://www.productontology.org/doc/Platform_as_a_service",
                "@id":"https://www.volterra.io/",
                "legalName": "Volterra Inc",
                "description": "Volterra offers a distributed cloud platform to deploy, connect, secure, observe and operate apps and data globally across multiple clouds and the edge",
                "url":"https://www.volterra.io/",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "San Jose, California, USA",
                  "postalCode": "CA 95134",
                  "streetAddress": "3545 N 1st St"
                },
                "email": "sales@volterra.io",
                "name": "Volterra Inc",
                "telephone": "+1 (408) 900-6788",
                "logo":"https://www.volterra.io/img/main/logo.svg",
                "image":"https://www.volterra.io/img/main/apps.png",
                "sameAs" :
                  [
                    "https://twitter.com/volterra_",
                    "https://www.linkedin.com/company/volterra-edge-services/",
                    "https://www.crunchbase.com/organization/volterra-inc"
                  ],
  
                "funder":
                  [
                    {
                      "@type": "Corporation",
                      "legalName": "Mayfield",
                      "url":"https://www.mayfield.com/",
                      "sameAs": "https://en.wikipedia.org/wiki/Mayfield_(company)"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Khosla Ventures",
                      "url":"https://www.khoslaventures.com/",
                      "sameAs": "https://en.wikipedia.org/wiki/Khosla_Ventures"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "(M12) Microsoftís venture fund",
                      "url":"https://m12.vc/",
                      "sameAs": "https://en.wikipedia.org/wiki/M12_(venture_capital)"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Samsung NEXT",
                      "url":"https://samsungnext.com/",
                      "sameAs": "https://www.crunchbase.com/organization/samsungnext"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Itochu Technology Ventures",
                      "url":"https://www.techv.co.jp/en/",
                      "sameAs": "https://www.crunchbase.com/organization/itochu-technology-ventures"
                    }
                  ],
  
                "owns":
                  [
                    {
                      "@type":"Product",
                      "name":"VoltShare",
                      "url":"https://www.volterra.io/products/voltshare",
                      "image":"https://www.volterra.io/img/products/voltshare.png",
                      "description":"Securely share your critical data while ensuring compliance. Simplify end-to-end encryption. Make your existing collaboration tools more secure."
                    },
                    {
                      "@type":"Product",
                      "name":"VoltMesh",
                      "url":"https://www.volterra.io/products/voltmesh",
                      "image":"https://www.volterra.io/img/products/voltmesh.png",
                      "description":"A SaaS-based offering that delivers high-performance networking and zero-trust security across clouds and edge sites along with the ability to offload apps on our global network"
                    },
                    {
                      "@type":"Product",
                      "name":"VoltStack",
                      "url":"https://www.volterra.io/products/voltstack",
                      "image":"https://www.volterra.io/img/products/voltstack.png",
                      "description":"SaaS-based offering that automates deployment, security and operations of distributed apps and infrastructure across the edge, public/private clouds and our global application delivery network"
                    },
                    {
                      "@type":"Product",
                      "name":"VoltConsole",
                      "url":"https://www.volterra.io/products/voltconsole",
                      "image":"https://www.volterra.io/img/products/voltconsole.png",
                      "description":"A SaaS-based operations and observability portal to manage infrastructure and apps across multi-cloud and edge, provide early warnings and generate actionable business insights"
                    },
                    {
                      "@type":"Product",
                      "name":"Volterra Global Network",
                      "url":"https://www.volterra.io/products/volterra-global-network",
                      "image":"https://www.volterra.io/img/products/global-network.png",
                      "description":"Industry's first app-to-app network and security service that's purpose built to connect and secure your global workloads across multi-cloud and edge"
                    }
                  ]
              }
          `}
        </script>
      </Helmet>

      <SEO
        title={metaTitle}
        pageUrl={"/company/compliance"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />

      {/* Hero */}
      <div className="compliance-container">
        <section className={styles.header}>
          <div className="container volterra-container">
            <div className="row">
              <h1 className={styles.header__title}>
                Volterra adheres to industry standard compliance certifications
                and security regulations to earn our customers’ trust
              </h1>
            </div>
          </div>
        </section>

        {/* Summary */}
        <section className={`${cblockStyles.cblock} ${styles.compliance}`}>
          <div className="container">
            <div className={cblockStyles.cblock__header}>
              <h2 className={cblockStyles.cblock__head}>
                Compliance at Volterra
              </h2>
            </div>
            <div className={cblockStyles.cblock__body}>
              <div className={styles.compliance__items}>
                {complianceData.map((compliance, i) => {
                  return (
                    <div className={`card ${styles.compliance__item}`} key={i}>
                      <div className={styles.compliance__itemHead}>
                        <img
                          src={compliance.img_url}
                          alt={compliance.img_alt}
                        />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: compliance.content,
                        }}></div>
                      <div className={styles.compliance__itemFoot}>
                        <a
                          href={compliance.readmore_link}
                          className={`link-icon link-icon-arrow ${styles.compliance__btnReadmore}`}
                          onClick={e => {
                            smoothScroll(compliance.ref, e)
                          }}>
                          Read More
                          <ArrowSvg />
                        </a>
                        {compliance.faq_link && (
                          <a
                            href={compliance.faq_link}
                            className={`link-icon ${styles.compliance__btnFaq}`}
                            target="_blank">
                            FAQ
                            <ExternalLinkSvg />
                          </a>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>

        {/* Description */}
        <section className={`${cblockStyles.cblock} ${styles.compliance}`}>
          <div className="container">
            {complianceData.map((compliance, i) => {
              return (
                <div
                  className={`row ${styles.compliance__fulldesc}`}
                  ref={compliance.ref}
                  key={i}>
                  <div className={`col-md-4 ${styles.compliance__fullimg}`}>
                    <img
                      className={`${
                        i === 0 ? styles.compliance__firstimg : ""
                      }`}
                      src={compliance.img_lg_url}
                      alt={compliance.img_alt}
                    />
                  </div>
                  <div className="col-md-8">
                    {compliance.full_desc.map((compitem, i) => {
                      return (
                        <div className={styles.compliance__desc} key={i}>
                          <h4>{compitem.title}</h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: compitem.description,
                            }}></div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default withAppContext(CompliancePage)
